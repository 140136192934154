import './App.css';

import HTMLFlipBook from 'react-pageflip';
import { Nav } from './components/Nav';
import { Footer } from './components/Footer';




function App() {
  return (
    <>
      <div style={{ justifyContent: "center", textAlign: "center" }}>
        <Nav />
        <div >
          {/* <p>Swipe Left</p> */}
          <HTMLFlipBook flippingTime={300} className="demo-book" style={{ justifyContent: "center", textAlign: "center" }} width={380} height={521}>
            {/* <div className="demoPage"><img style={{ width: "390px", height: "550px" }} src='' /></div> */}
            {/* <div className="demoPage"><img className='rkp-img-full rkp-suffle-lap' src='assets/images/Roster_Page-15.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full rkp-suffle-mob' src='assets/images/swipe-01.jpg' /></div> */}
            {/* <div className="demoPage"><img className='rkp-img-full rkp-suffle-lap' src='assets/images/Roster_Page-15.jpg' /></div> */}
            {/* <div className="demoPage"><img className='rkp-img-full' src='assets/images/swipe-01.jpg' /></div> */}
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-01.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-02.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-03.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-04.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-05.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-06.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-07.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-08.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-09.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-10.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-11.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-12.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-13.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-14.jpg' /></div>
            <div className="demoPage"><img className='rkp-img-full' src='assets/images/Roster_Page-16.jpg' /></div>
          </HTMLFlipBook>
          {/* <p>Swipe Right</p> */}
        </div>
        <div className="rkp-footer">

          <Footer />
        </div>
      </div>

    </>
  );
}

export default App;
