import React from 'react'

export const Nav = () => {
    return (
        <div className='rkp-nav'>
            <div className='rkp-nav-logo rkp-logo-mob' style={{}}>
                <img className='rkp-logo-img' src='assets/images/Jeet H 2.png' alt="Logo" />
                <div className='rkp-btn-mob'>
                    <a style={{ textDecoration: "none", color: "#d0343f" }} href='assets/pdf/bni_jeet_roster.pdf' download="bni_jeet_rooster.pdf" target='_blank' rel="noopener noreferrer">Download</a>
                </div>
            </div>
            <div className='rkp-nav-btn-prt'>
                {/* <button className='rkp-button'> */}
                <a className='rkp-nav-btn' href='assets/pdf/bni_jeet_roster.pdf' download="bni_jeet_rooster.pdf" target='_blank' rel="noopener noreferrer">
                    Download Now
                </a>
                {/* </button> */}
            </div>

        </div>

    )
}
